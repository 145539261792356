import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateMenuThunk } from '../redux/menusSlice';
import Input from './modal/Input';
import Toggle from './modal/Toggle';
import Modal from './modal/Modal';
import ImageSection from './modal/ImageSection';
import ColorSection from './modal/ColorSection';
import Translations from './modal/Translations';
const updateMenu = require('./logic/updateMenu.js');

const CreateMenuCategory = ({ 
    owner, 
    menu, 
    menuId, 
    showCreateMenuCategory, 
    setShowCreateMenuCategory, 
    refreshAllMenusOwner, 
    refreshMenuOwner, 
    menuDefaultLanguage 
}) => {

    const url = process.env.REACT_APP_SERVER_BASE_URL;
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const isLoading = useSelector((state) => state.menus.menuUpdatingIsLoading);
    const errorMessage = useSelector((state) => state.menus.errorMenuUpdating);
    const successMessage = useSelector((state) => state.menus.successMenuUpdating);
    const [forceHideSaveButton, setForceHideSaveButton] = useState(false);
    const [translations, setTranslations] = useState([]);
    const [outputTranslations, setOutputTranslations] = useState([]);
    const defaultLanguage = menuDefaultLanguage || 'ita';

    // Added state variables for validation and upload handling
    const [nameError, setNameError] = useState(false); // Tracks validation error for "Nome"
    const [uploadMessage, setUploadMessage] = useState(false); // Handles upload messages
    const [uploadErrorState, setUploadErrorState] = useState(false); // Handles upload errors

    // Initialize category details with translations
    const [categoryDetails, setCategoryDetails] = useState({
        color: '',
        image: '',
        price: '',
        isComplete: false,
        translations: menu.menu.translations
            .map(lang => ({
                language: lang.language,
                name: '',
                description: ''
            }))
    });

    // Update image URL when a new file is uploaded
    const [uploadedFileName, setUploadedFileName] = useState(null);

    useEffect(() => {
        if (uploadedFileName) {
            setCategoryDetails(prevState => ({
                ...prevState,
                image: uploadedFileName
            }));
        }
    }, [uploadedFileName]);

    // Set initial image if exists
    const [inputImage, setInputImage] = useState('');

    useEffect(() => {
        if (categoryDetails.image) {
            setInputImage(categoryDetails.image);
        }
    }, [categoryDetails.image]); // Added dependency to avoid warnings

    const handleRemoveImage = () => {
        setCategoryDetails(prevState => ({
            ...prevState,
            image: ''
        }));

        setUploadMessage(false);
        setUploadErrorState(false);
        document.getElementById('fileInput').value = '';
    };

    const [color, setColor] = useState(categoryDetails?.color || '');

    const handleColorChange = (color) => {
        setColor(color.hex);
        setCategoryDetails(prevState => ({
            ...prevState,
            color: color.hex
        }));
    };

    const deleteColor = () => {
        setColor('');
        setCategoryDetails(prevState => ({
            ...prevState,
            color: ''
        }));
    };

    useEffect(() => {
        if (outputTranslations && outputTranslations.length > 0) {
            const formattedTranslations = outputTranslations
                .map(lang => ({
                    language: lang.language,
                    name: lang.name,
                    description: lang.description
                }));
            setCategoryDetails(prevCategoryDetails => ({
                ...prevCategoryDetails,
                translations: formattedTranslations || []
            }));
        }
    }, [outputTranslations]);

    const closeCreateMenuCategory = () => {
        setShowCreateMenuCategory(false);
    };

    const dispatch = useDispatch();

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        const inputValue = type === 'checkbox' ? checked : value;

        if (name === 'name' || name === 'description') {
            if (name === 'name' && value.trim() !== '') {
                setNameError(false);
                setError(false);
            }
            setCategoryDetails(prevState => ({
                ...prevState,
                translations: prevState.translations.map(translation =>
                    translation.language === defaultLanguage
                        ? { ...translation, [name]: inputValue }
                        : translation
                )
            }));
        } else {
            setCategoryDetails(prevState => ({
                ...prevState,
                [name]: inputValue
            }));
        }
    };

    const handleToggle = () => {
        setCategoryDetails(prevState => {
            const newIsComplete = !prevState.isComplete;
            return {
                ...prevState,
                isComplete: newIsComplete,
                price: newIsComplete ? prevState.price : '' 
            };
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Validation
        const name = categoryDetails.translations.find(t => t.language === defaultLanguage)?.name.trim();
        if (!name) {
            setNameError(true);
            setError(true);
            return;
        }

        setLoading(true);
        setError(false);
        setSuccess(false);

        const updatedMenu = updateMenu(menu, categoryDetails, 'categoryCreate', null);

        try {
            const response = await dispatch(updateMenuThunk({ menuId, updatedMenu }));
            if (updateMenuThunk.fulfilled.match(response)) {
                setLoading(false);
                setSuccess(true);
                if (error) {
                    setError(false);
                }
                await refreshMenuOwner(menuId);
                closeCreateMenuCategory(); 
            } else {
                setLoading(false);
                setError(true);
            }
        } catch (error) {
            console.error('Error updating the menu:', error);
            setLoading(false);
            setError(true);
        }
    };

    return (

        <>

            {showCreateMenuCategory &&
                <Modal 
                    handleSubmit={handleSubmit}
                    closeFunction={closeCreateMenuCategory}
                    title="Crea Categoria Menu"
                    isLoading={isLoading}
                    loading={loading}
                    success={success}
                    successMessage={successMessage}
                    error={error}
                    errorMessage={errorMessage}
                    // Assuming your Modal component can handle a deleteFunction prop if needed
                >

                    <Input 
                        description={categoryDetails.translations.find(t => t.language === defaultLanguage)?.name || ''}
                        name='name'
                        onChange={handleInputChange}
                        tipo="InputText"
                        hasError={nameError} // Pass the error state
                        ariaDescribedby="name-error"
                    >
                        <div className="text-2xl">
                            Nome <span className="text-red-500">*</span> {/* Indicate required */}
                        </div>
                    </Input>
                    { nameError && <span id="name-error" className="text-red-500">Il nome della categoria è obbligatorio.</span> }

                    <Input 
                        description={categoryDetails.translations.find(t => t.language === defaultLanguage)?.description || ''}
                        name='description'
                        onChange={handleInputChange}
                        tipo="InputText"
                    >
                        <div className="text-2xl">Descrizione</div>
                    </Input>

                    <hr className="border-gray-400 mt-8"></hr>
                    <div className="text-2xl font-bold mt-4">Traduzioni</div>

                    <Translations
                        defaultLanguage={defaultLanguage}
                        inputTranslations={categoryDetails.translations}
                        outputTranslations={outputTranslations}
                        setOutputTranslations={setOutputTranslations}
                    ></Translations>

                    <hr className="border-gray-400 mt-8"></hr>
                    <div className="text-2xl font-bold mt-4">Tipo di menu</div>

                    <div className='flex flex-col items-start mt-6'>
                        <div className='flex flex-row items-center justify-center gap-2 my-1'>
                            <span className='font-bold'>Menu Completo</span>

                            <div >
                                <Toggle
                                    isOn={categoryDetails.isComplete}
                                    handleToggle={handleToggle}
                                />
                            </div>
                        </div>
                        {categoryDetails.isComplete &&
                            <div className='flex flex-row justify-between gap-2 items-center my-1'>
                                <h3 className='font-bold'>Prezzo</h3>
                                <div className='p-2 w-40 flex flex-row justify-between items-center'>
                                    <input
                                        className='border rounded-lg border-gray-400 p-2 bg-transparent'
                                        value={categoryDetails.price}
                                        name='price'
                                        onChange={handleInputChange}
                                        type='number'
                                        min="0"
                                    />
                                </div>
                            </div>}
                    </div>
                    <hr className="border-gray-400 mt-8"></hr>
                    <div className="text-2xl font-bold mt-4">Layout</div>

                    <ColorSection
                        handleColorChange={handleColorChange}
                        deleteColor={deleteColor}
                        color={color}
                    ></ColorSection>

                    <ImageSection
                        inputImage={inputImage}
                        handleRemoveImage={handleRemoveImage}
                        setUploadedFileName={setUploadedFileName}
                        aspect={3/1}
                        maxSizeMB={0.3}
                        maxWidthOrHeight={1920}
                        uploadMessage={uploadMessage}
                        setUploadMessage={setUploadMessage}
                        uploadError={uploadErrorState}
                        setUploadError={setUploadErrorState}
                    ></ImageSection>

                    <hr className="border-gray-400 mt-4"></hr>

                </Modal>
            }

        </>
    )
}

export default CreateMenuCategory;
