import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { create } from '../redux/menusSlice';
import Input from './modal/Input';
import Toggle from './modal/Toggle';
import Modal from './modal/Modal';
import ImageSection from './modal/ImageSection';
import ColorSection from './modal/ColorSection';
import Translations from './modal/Translations';
import TimeSlots from './modal/TimeSlots'; // Ensure correct path
const updateMenu = require('./logic/updateMenu.js');

const CreateMenu = ({ owner, menu, showCreateMenu, setShowCreateMenu, refreshAllMenusOwner }) => {
    const url = process.env.REACT_APP_SERVER_BASE_URL;
    const [showCreateMenuTranslations, setShowCreateMenuTranslations] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [translations, setTranslations] = useState([]);
    const [outputTranslations, setOutputTranslations] = useState([]);
    const isLoading = useSelector((state) => state.menus.isLoadingCreate);
    const errorMessage = useSelector((state) => state.menus.errorCreate);
    const successMessage = useSelector((state) => state.menus.successCreate);
    const [forceHideSaveButton, setForceHideSaveButton] = useState(false);
    const [imageFileName, setImageFileName] = useState('');
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadMessage, setUploadMessage] = useState(false);
    const [uploadError, setUploadError] = useState(false);

    const [menuDetails, setMenuDetails] = useState({
        defaultLanguage: 'ita',
        settings: {
            alwaysActive: true,
            daysActive: [],
            timeSlot: {
                startTime: '',
                endTime: ''
            },
            timeSlots: []
        },
        menu: {
            color: '',
            image: '',
            translations: [
                { language: 'ita', name: '', description: '' },
                { language: 'fra', name: '', description: '' },
                { language: 'spa', name: '', description: '' },
                { language: 'eng', name: '', description: '' },
                { language: 'deu', name: '', description: '' },
            ]
        }
    });

    const [defaultLanguage, setDefaultLanguage] = useState('ita');
    const [nameError, setNameError] = useState(false); // Added state for name error

    const languages = menuDetails.menu.translations
        .map(lang => lang.language);

    useEffect(() => {
        if (outputTranslations && outputTranslations.length > 0) {
            setMenuDetails(prevState => {
                const updatedTranslations = prevState.menu.translations.map(existingTranslation => {
                    const newTranslation = outputTranslations.find(translation => translation.language === existingTranslation.language);
                    return newTranslation ? { ...existingTranslation, ...newTranslation } : existingTranslation;
                });
                return {
                    ...prevState,
                    menu: {
                        ...prevState.menu,
                        translations: updatedTranslations
                    }
                };
            });
        }
    }, [outputTranslations]);

    const [filteredLanguages, setFilteredLanguages] = useState([]);

    const handleLanguageChange = (e) => {
        const selectedLanguage = e.target.value;
        setDefaultLanguage(selectedLanguage);
        setFilteredLanguages(languages.filter(language => language !== selectedLanguage));
        setMenuDetails(prevState => ({
            ...prevState,
            defaultLanguage: selectedLanguage
        }));
    };

    const [uploadedFileName, setUploadedFileName] = useState(false);

    useEffect(() => {
        setMenuDetails(prevState => ({
            ...prevState,
            menu: {
                ...prevState.menu,
                image: uploadedFileName // URL to the uploaded file
            }
        }));
    }, [uploadedFileName]);

    const handleRemoveImage = () => {
        setMenuDetails(prevState => ({
            ...prevState,
            menu: {
                ...prevState.menu,
                image: ''
            }
        }));
        setUploadMessage(false);
        setUploadError(false);
        document.getElementById('fileInput').value = '';
    };

    const [color, setColor] = useState('');

    const handleColorChange = (color) => {
        setColor(color.hex);
        setMenuDetails(prevState => ({
            ...prevState,
            menu: {
                ...prevState.menu,
                color: color.hex
            }
        }));
    };

    const deleteColor = () => {
        setColor('');
        setMenuDetails(prevState => ({
            ...prevState,
            menu: {
                ...prevState.menu,
                color: ''
            }
        }));
    };

    const closeCreateMenu = () => {
        setShowCreateMenu(false);
    };

    const dispatch = useDispatch();

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        const inputValue = type === 'checkbox' ? checked : value;

        if (name === 'name' || name === 'description') {
            if (name === 'name' && value.trim() !== '') {
                setNameError(false);
            }
            setMenuDetails(prevState => ({
                ...prevState,
                menu: {
                    ...prevState.menu,
                    translations: prevState.menu.translations.map(translation =>
                        translation.language === defaultLanguage
                            ? { ...translation, [name]: inputValue }
                            : translation
                    )
                }
            }));
        }
    };

    const handleToggle = () => {
        const alwaysActive = !menuDetails.settings.alwaysActive;
        setMenuDetails({
            ...menuDetails,
            settings: {
                ...menuDetails.settings,
                alwaysActive: alwaysActive,
                daysActive: alwaysActive ? [] : menuDetails.settings.daysActive,
                timeSlot: {
                    startTime: alwaysActive ? '' : menuDetails.settings.timeSlot.startTime,
                    endTime: alwaysActive ? '' : menuDetails.settings.timeSlot.endTime,
                }
            }
        });
    };

    const handleAddTimeSlot = (dayIndex) => {
        setMenuDetails(prevState => {
            const existingSlot = prevState.settings.timeSlots.find(slot => slot.day === dayIndex);
            if (existingSlot) {
                return {
                    ...prevState,
                    settings: {
                        ...prevState.settings,
                        timeSlots: prevState.settings.timeSlots.map(slot =>
                            slot.day === dayIndex
                                ? { ...slot, slots: [...slot.slots, { startTime: '', endTime: '' }] }
                                : slot
                        )
                    }
                };
            } else {
                return {
                    ...prevState,
                    settings: {
                        ...prevState.settings,
                        timeSlots: [
                            ...prevState.settings.timeSlots,
                            { day: dayIndex, slots: [{ startTime: '', endTime: '' }] }
                        ]
                    }
                };
            }
        });
    };

    const handleRemoveTimeSlot = (dayIndex, slotIndex) => {
        setMenuDetails(prevState => {
            const updatedTimeSlots = prevState.settings.timeSlots.map(slot => {
                if (slot.day === dayIndex) {
                    return {
                        ...slot,
                        slots: slot.slots.filter((_, idx) => idx !== slotIndex)
                    };
                }
                return slot;
            });

            return {
                ...prevState,
                settings: {
                    ...prevState.settings,
                    timeSlots: updatedTimeSlots
                }
            };
        });
    };

    const handleTimeSlotChange = (dayIndex, slotIndex, field, value) => {
        setMenuDetails(prevState => {
            const updatedTimeSlots = prevState.settings.timeSlots.map(slot => {
                if (slot.day === dayIndex) {
                    return {
                        ...slot,
                        slots: slot.slots.map((s, idx) => {
                            if (idx === slotIndex) {
                                return {
                                    ...s,
                                    [field]: value
                                };
                            }
                            return s;
                        })
                    };
                }
                return slot;
            });

            return {
                ...prevState,
                settings: {
                    ...prevState.settings,
                    timeSlots: updatedTimeSlots
                }
            };
        });
    };

    const handleDayToggle = (dayIndex) => {
        setMenuDetails(prevState => {
            const existingSlot = prevState.settings.timeSlots.find(slot => slot.day === dayIndex);

            let updatedTimeSlots;
            if (existingSlot) {
                updatedTimeSlots = prevState.settings.timeSlots.filter(slot => slot.day !== dayIndex);
            } else {
                updatedTimeSlots = [
                    ...prevState.settings.timeSlots,
                    { day: dayIndex, slots: [{ startTime: '', endTime: '' }] }
                ];
            }

            return {
                ...prevState,
                settings: {
                    ...prevState.settings,
                    timeSlots: updatedTimeSlots
                }
            };
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Validation
        const name = menuDetails.menu.translations.find(t => t.language === defaultLanguage)?.name.trim();
        if (!name) {
            setNameError(true);
            setError(true);
            return;
        }

        setLoading(true);
        setError(false);
        setSuccess(false);

        const updatedMenu = updateMenu(null, menuDetails, 'menuCreate', owner._id);

        try {
            const response = await dispatch(create(updatedMenu));
            if (create.fulfilled.match(response)) {
                setLoading(false);
                setSuccess(true);
                if (error) {
                    setError(false);
                }
                await refreshAllMenusOwner(owner._id);
                closeCreateMenu(); 
            } else {
                setLoading(false);
                setError(true);
            }
        } catch (error) {
            console.error('Error updating the menu:', error);
            setLoading(false);
            setError(true);
        }
    };

    return (
        <>
            {showCreateMenu &&
                <Modal 
                    handleSubmit={handleSubmit}
                    closeFunction={closeCreateMenu}
                    title="Crea Menu"
                    isLoading={isLoading}
                    loading={loading}
                    success={success}
                    successMessage={successMessage}
                    error={error}
                    errorMessage={errorMessage}
                >
                    <Input 
                        description={menuDetails.menu.translations.find(t => t.language === defaultLanguage)?.name || ''}
                        name='name'
                        onChange={handleInputChange}
                        tipo="InputText"
                        hasError={nameError} // Pass the error state
                    >
                        <div className="text-2xl">
                            Nome <span className="text-red-500">*</span> {/* Indicate required */}
                        </div>
                    </Input>
                    { nameError && <span className="text-red-500">Il nome del menu è obbligatorio.</span> }

                    <Input 
                        description={menuDetails.menu.translations.find(t => t.language === defaultLanguage)?.description || ''}
                        name='description'
                        onChange={handleInputChange}
                        tipo="InputText"
                    >
                        <div className="text-2xl">Descrizione</div>
                    </Input>

                    <hr className="border-gray-400 mt-8"></hr>
                  
                    <div className="text-2xl font-bold mt-4">Traduzioni</div>
                    <Translations
                        languages={languages}
                        defaultLanguage={defaultLanguage}
                        handleLanguageChange={handleLanguageChange}
                        inputTranslations={menuDetails.menu.translations}
                        outputTranslations={outputTranslations}
                        setOutputTranslations={setOutputTranslations}
                    ></Translations>

                    <hr className="border-gray-400 mt-8"></hr>
                    <div className="text-2xl font-bold mt-4">Orari</div>

                    <div className='flex flex-col items-start mt-6'>
                        <div className='flex flex-row items-center justify-center gap-2 my-1 mb-4'>
                            <span className='font-bold'>Attivo sempre</span>

                            <div>
                                <Toggle
                                    isOn={menuDetails.settings.alwaysActive}
                                    handleToggle={handleToggle}
                                />
                            </div>
                        </div>
                        {!menuDetails.settings.alwaysActive &&
                            (
                                <TimeSlots
                                    timeSlots={menuDetails.settings.timeSlots}
                                    handleDayToggle={handleDayToggle}
                                    handleTimeSlotChange={handleTimeSlotChange}
                                    handleRemoveTimeSlot={handleRemoveTimeSlot}
                                    handleAddTimeSlot={handleAddTimeSlot}
                                />
                            )
                        }
                    </div>

                    <hr className="border-gray-400 mt-4 mb-4 w-full"></hr>

                    <div className="text-2xl font-bold mt-4">Layout</div>

                    <ColorSection
                        handleColorChange={handleColorChange}
                        deleteColor={deleteColor}
                        color={color}
                    ></ColorSection>

                    <ImageSection
                        handleRemoveImage={handleRemoveImage}
                        setUploadedFileName={setUploadedFileName}
                        aspect={3 / 1}
                        maxSizeMB={0.3}
                        maxWidthOrHeight={1920}
                    ></ImageSection>

                    <hr className="border-gray-400 mt-4"></hr>

                </Modal>
            }
        </>
    );
};

export default CreateMenu;
