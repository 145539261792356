import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateMenuThunk, deleteMenu, getAllMenusOwner } from '../redux/menusSlice';
import Input from './modal/Input';
import Toggle from './modal/Toggle';
import Modal from './modal/Modal';
import ImageSection from './modal/ImageSection';
import ColorSection from './modal/ColorSection';
import Translations from './modal/Translations';
import TimeSlots from './modal/TimeSlots';
const updateMenu = require('./logic/updateMenu.js');

const EditMenu = ({ menu, owner, showEditMenu, setShowEditMenu, refreshMenuOwner, refreshAllMenusOwner, setSelectedMenuId, getHandleDeleteMenu }) => {

    const url = process.env.REACT_APP_SERVER_BASE_URL;
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const isLoading = useSelector((state) => state.menus.menuUpdatingIsLoading);
    const errorMessage = useSelector((state) => state.menus.errorMenuUpdating);
    const successMessage = useSelector((state) => state.menus.successMenuUpdating);
    const [selectedMenuDetails, setSelectedMenuDetails] = useState(null);
    const [translations, setTranslations] = useState([]);
    const [outputTranslations, setOutputTranslations] = useState([]);
    const defaultLanguage = menu.defaultLanguage;

    const [menuDetails, setMenuDetails] = useState({
        defaultLanguage: menu.defaultLanguage || 'ita',
        settings: {
            alwaysActive: menu.settings?.alwaysActive || false,
            daysActive: menu.settings?.daysActive || [],
            timeSlot: {
                startTime: menu.settings?.timeSlot?.startTime || '',
                endTime: menu.settings?.timeSlot?.endTime || ''
            },
            timeSlots: menu.settings?.timeSlots || []
        },
        menu: {
            color: menu.menu?.color || '',
            image: menu.menu?.image || '',
            translations: [
                {
                    language: 'ita',
                    name: menu.menu?.translations?.find(t => t.language === 'ita')?.name || '',
                    description: menu.menu?.translations?.find(t => t.language === 'ita')?.description || ''
                },
                {
                    language: 'fra',
                    name: menu.menu?.translations?.find(t => t.language === 'fra')?.name || '',
                    description: menu.menu?.translations?.find(t => t.language === 'fra')?.description || ''
                },
                {
                    language: 'spa',
                    name: menu.menu?.translations?.find(t => t.language === 'spa')?.name || '',
                    description: menu.menu?.translations?.find(t => t.language === 'spa')?.description || ''
                },
                {
                    language: 'eng',
                    name: menu.menu?.translations?.find(t => t.language === 'eng')?.name || '',
                    description: menu.menu?.translations?.find(t => t.language === 'eng')?.description || ''
                },
                {
                    language: 'deu',
                    name: menu.menu?.translations?.find(t => t.language === 'deu')?.name || '',
                    description: menu.menu?.translations?.find(t => t.language === 'deu')?.description || ''
                }
            ]
        }
    });

    const [nameError, setNameError] = useState(false); // Added state for name error
    const [uploadMessage, setUploadMessage] = useState(false); // Added state for upload message
    const [uploadErrorState, setUploadErrorState] = useState(false); // Renamed to avoid conflict with 'error'

    useEffect(() => {

        if (outputTranslations && outputTranslations.length > 0) {
            setMenuDetails(prevState => {
                const updatedTranslations = prevState.menu.translations.map(existingTranslation => {
                    const newTranslation = outputTranslations.find(translation => translation.language === existingTranslation.language);
                    return newTranslation ? { ...existingTranslation, ...newTranslation } : existingTranslation;
                });
                return {
                    ...prevState,
                    menu: {
                        ...prevState.menu,
                        translations: updatedTranslations
                    }
                };
            });
        }
    }, [outputTranslations]);

    const [uploadedFileName, setUploadedFileName] = useState(false);

    useEffect(() => {
        if (uploadedFileName) {
            setMenuDetails(prevState => ({
                ...prevState,
                menu: {
                    ...prevState.menu,
                    image: uploadedFileName // URL to the uploaded file
                }
            }));
        }
    }, [uploadedFileName]);

    const [inputImage, setInputImage] = useState('');

    useEffect(() => {
        if (menu.menu.image) {
            setInputImage(menu.menu.image);
        }
    }, [menu.menu.image]); // Added dependency to avoid warnings

    const handleRemoveImage = () => {
        setMenuDetails(prevState => ({
            ...prevState,
            menu: {
                ...prevState.menu,
                image: ''
            }
        }));

        setUploadMessage(false);
        setUploadErrorState(false);
        document.getElementById('fileInput').value = '';
    };

    const [color, setColor] = useState(menu.menu?.color || '');

    const handleColorChange = (color) => {
        setColor(color.hex);
        setMenuDetails(prevState => ({
            ...prevState,
            menu: {
                ...prevState.menu,
                color: color.hex
            }
        }));
    };

    const deleteColor = () => {
        setColor('');
        setMenuDetails(prevState => ({
            ...prevState,
            menu: {
                ...prevState.menu,
                color: ''
            }
        }));
    };

    const handleAddTimeSlot = (dayIndex) => {
        setMenuDetails(prevState => ({
            ...prevState,
            settings: {
                ...prevState.settings,
                timeSlots: prevState.settings.timeSlots.map(slot => {
                    if (slot.day === dayIndex) {
                        return {
                            ...slot,
                            slots: [...slot.slots, { startTime: '', endTime: '' }]
                        };
                    }
                    return slot;
                })
            }
        }));
    };

    const handleRemoveTimeSlot = (dayIndex, slotIndex) => {
        setMenuDetails(prevState => {
            const updatedTimeSlots = prevState.settings.timeSlots.map(slot => {
                if (slot.day === dayIndex) {
                    return {
                        ...slot,
                        slots: slot.slots.filter((_, idx) => idx !== slotIndex)
                    };
                }
                return slot;
            });

            return {
                ...prevState,
                settings: {
                    ...prevState.settings,
                    timeSlots: updatedTimeSlots
                }
            };
        });
    };


    const handleTimeSlotChange = (dayIndex, slotIndex, field, value) => {
        setMenuDetails(prevState => {
            const updatedTimeSlots = prevState.settings.timeSlots.map(slot => {
                if (slot.day === dayIndex) {
                    return {
                        ...slot,
                        slots: slot.slots.map((s, idx) => {
                            if (idx === slotIndex) {
                                return {
                                    ...s,
                                    [field]: value
                                };
                            }
                            return s;
                        })
                    };
                }
                return slot;
            });

            return {
                ...prevState,
                settings: {
                    ...prevState.settings,
                    timeSlots: updatedTimeSlots
                }
            };
        });
    };

    const handleDayToggle = (dayIndex) => {
        setMenuDetails(prevState => {
            const existingSlot = prevState.settings.timeSlots.find(slot => slot.day === dayIndex);

            let updatedTimeSlots;
            if (existingSlot) {
                updatedTimeSlots = prevState.settings.timeSlots.filter(slot => slot.day !== dayIndex);
            } else {
                updatedTimeSlots = [
                    ...prevState.settings.timeSlots,
                    { day: dayIndex, slots: [{ startTime: '', endTime: '' }] }
                ];
            }

            return {
                ...prevState,
                settings: {
                    ...prevState.settings,
                    timeSlots: updatedTimeSlots
                }
            };
        });
    };

    const closeEditMenu = () => {
        setShowEditMenu(false);
    };

    const dispatch = useDispatch();

    const weekDays = ['Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab', 'Dom'];

    const isDayActive = (dayIndex) => {
        return menuDetails.settings.daysActive.includes(dayIndex);
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        const inputValue = type === 'checkbox' ? checked : value;

        if (name === 'name' || name === 'description') {
            if (name === 'name' && value.trim() !== '') {
                setNameError(false);
                setError(false);
            }
            setMenuDetails(prevState => ({
                ...prevState,
                menu: {
                    ...prevState.menu, 
                    translations: prevState.menu.translations.map(translation =>
                        translation.language === defaultLanguage
                            ? { ...translation, [name]: inputValue } 
                            : translation
                    )
                }
            }));
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Validation
        const name = menuDetails.menu.translations.find(t => t.language === defaultLanguage)?.name.trim();
        if (!name) {
            setNameError(true);
            setError(true);
            return;
        }

        setLoading(true);
        setError(false);
        setSuccess(false);

        const updatedMenu = updateMenu(menu, menuDetails, 'menuUpdate', menu._id); // Passa l'ID del menu

        try {
            const response = await dispatch(updateMenuThunk({ menuId: menu._id, updatedMenu }));

            if (updateMenuThunk.fulfilled.match(response)) {
                setLoading(false);
                setSuccess(true);
                if (error) {
                    setError(false);
                }
                await refreshAllMenusOwner(owner._id); 
                await refreshMenuOwner(owner._id); 

                closeEditMenu(); 
            } else {
                setLoading(false);
                setError(true);
            }
        } catch (error) {
            console.error('Error updating the menu:', error);
            setLoading(false);
            setError(true);
        }
    };

    const handleToggle = () => {
        const alwaysActive = !menuDetails.settings.alwaysActive;
        setMenuDetails({
            ...menuDetails,
            settings: {
                ...menuDetails.settings,
                alwaysActive: alwaysActive,
                daysActive: alwaysActive ? [] : menuDetails.settings.daysActive,
                timeSlot: {
                    startTime: alwaysActive ? '' : menuDetails.settings.timeSlot.startTime,
                    endTime: alwaysActive ? '' : menuDetails.settings.timeSlot.endTime,
                }
            }
        });
    };

    return (

        <>

            {showEditMenu && menu && menu.menu && menu.settings && menu.settings.timeSlot &&
                <Modal 
                    handleSubmit={handleSubmit}
                    closeFunction={closeEditMenu}
                    title="Modifica Menu"
                    isLoading={isLoading}
                    loading={loading}
                    success={success}
                    successMessage={successMessage}
                    error={error}
                    errorMessage={errorMessage}
                    deleteFunction={getHandleDeleteMenu(menu._id)}
                >

                    <Input 
                        description={menuDetails.menu.translations.find(t => t.language === defaultLanguage)?.name || ''}
                        name='name'
                        onChange={handleInputChange}
                        tipo="InputText"
                        hasError={nameError} // Pass the error state
                        ariaDescribedby="name-error"
                    >
                        <div className="text-2xl">
                            Nome <span className="text-red-500">*</span> {/* Indicate required */}
                        </div>
                    </Input>
                    { nameError && <span id="name-error" className="text-red-500">Il nome del menu è obbligatorio.</span> }

                    <Input 
                        description={menuDetails.menu.translations.find(t => t.language === defaultLanguage)?.description || ''}
                        name='description'
                        onChange={handleInputChange}
                        tipo="InputText"
                    >
                        <div className="text-2xl">Descrizione</div>
                    </Input>

                    <hr className="border-gray-400 mt-8"></hr>
                    <div className="text-2xl font-bold mt-4">Traduzioni</div>

                    <Translations
                        defaultLanguage={defaultLanguage}
                        inputTranslations={menuDetails.menu.translations}
                        outputTranslations={outputTranslations}
                        setOutputTranslations={setOutputTranslations}
                    ></Translations>


                    <hr className="border-gray-400 mt-8"></hr>
                    <div className="text-2xl font-bold mt-4">Orari</div>

                    <div className='flex flex-col items-start mt-6'>
                        <div className='flex flex-row items-center justify-center gap-2 my-1'>
                            <span className='font-bold'>Attivo sempre</span>

                            <div>
                                <Toggle
                                    isOn={menuDetails.settings.alwaysActive}
                                    handleToggle={handleToggle}
                                />
                            </div>
                        </div>
                        {!menuDetails.settings.alwaysActive &&
                            (
                                <TimeSlots
                                    timeSlots={menuDetails.settings.timeSlots}
                                    handleDayToggle={handleDayToggle}
                                    handleTimeSlotChange={handleTimeSlotChange}
                                    handleRemoveTimeSlot={handleRemoveTimeSlot}
                                    handleAddTimeSlot={handleAddTimeSlot}
                                />
                            )
                        }
                    </div>

                    <hr className="border-gray-400 mt-4 mb-4 w-full"></hr>

                    <div className="text-2xl font-bold mt-4">Layout</div>

                    <ColorSection
                        handleColorChange={handleColorChange}
                        menu={menu}
                        deleteColor={deleteColor}
                        color={color}
                    ></ColorSection>

                    <ImageSection
                        inputImage={inputImage}
                        handleRemoveImage={handleRemoveImage}
                        setUploadedFileName={setUploadedFileName}
                        aspect={3 / 1}
                        maxSizeMB={0.3}
                        maxWidthOrHeight={1920}
                        uploadMessage={uploadMessage}
                        setUploadMessage={setUploadMessage}
                        uploadError={uploadErrorState}
                        setUploadError={setUploadErrorState}
                    ></ImageSection>

                    <hr className="border-gray-400 mt-4"></hr>

                </Modal>

            }

        </>
    )
}

export default EditMenu;
