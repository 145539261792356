import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import nemuPosterMenuVideo from '../asset/Restaurant.png';
import nemuPosterConfVideo from '../asset/Cliente.jpg';
import Button from '../components/modal/Buttons'
import SignUpModal from '../components/SignUpModal';
import { FaPizzaSlice, FaUtensils, FaBeer, FaCoffee } from 'react-icons/fa';
import QRCode from 'react-qr-code';



const MenuDigitalePage = () => {
    const [showRegistration, setShowRegistration] = useState(false);
    const navigate = useNavigate();
    const restaurantUrl = 'https://menu.nemu-app.com/menu/6707a75908d31f5a2be1f262/67082f7708d31f5a2be20467'
    const pizzeriaUrl = 'https://menu.nemu-app.com/menu/6707a75908d31f5a2be1f262/67082f6f08d31f5a2be2036e'
    const barUrl = 'https://menu.nemu-app.com/menu/6707a75908d31f5a2be1f262/670ce1fb857b163431c9aedc'
    const pubUrl = 'https://menu.nemu-app.com/menu/6707a75908d31f5a2be1f262/670ce204857b163431c9af55'

    const handleRegisterClick = () => {
        setShowRegistration(true);
    };

    const handleChangeRegistration = () => {
        setShowRegistration(!showRegistration);
    };

    const handleGoToPromo = () => {
        navigate('/#promo');

    };

    return (
        <div className="container mx-auto p-4 md:p-2 mt-8 max-w-5xl green-background-text">
            <main>
                {/* Prima sezione */}
                <section className="bg-green-50 p-10 rounded-2xl shadow-xl flex flex-col md:flex-row items-center gap-8 mb-16 mt-12">
                    <div className="md:w-1/3">
                        <h2 className="text-3xl font-extrabold mb-6">Personalizza il tuo menu con un click</h2>
                        <ul className="list-disc pl-5 text-lg text-gray-600">
                            <li className="mb-3">Menu digitale con interfaccia intuitiva e facile da usare</li>
                            <li className="mb-3">Creazione e aggiornamento del menu in tempo reale</li>
                            <li className="mb-3">Gestione multilingue</li>
                            <li className="mb-3">Informazioni su ingredienti e allergeni</li>
                            <li>Gratuito a vita per i primi ristoranti che aderiranno</li>
                        </ul>
                    </div>
                    <div className="md:w-2/3">
                        <video className="w-full rounded-2xl shadow-lg transform hover:scale-105 transition duration-300"
                            controls
                            poster={nemuPosterMenuVideo}
                        >
                            <source src={'https://images.nemu-app.com/nemu_demo_configuratore_v2.mp4'} type="video/mp4" />
                            Il tuo browser non supporta il tag video.
                        </video>
                    </div>
                </section>

                {/* Sezione con QR Code e scorrimento orizzontale */}
                <section className=" p-4 border rounded-2xl shadow-xl flex flex-col items-center gap-2 mb-16">
                    <h3 className="text-3xl font-extrabold text-center mb-6">Menu di esempio</h3>
                    <div className="flex overflow-x-auto space-x-3">
                        {/* Pizzeria */}
                        <div className="flex-shrink-0 w-56 flex flex-col items-center p-2 bg-white border border-gray-300 rounded-2xl  text-center">
                            <div className="flex flex-row items-center">
                                <FaPizzaSlice size={40} className="mb-4 text-green-500 mr-4" />
                                <h4 className="text-3xl font-semibold mb-4 text-green-500">Pizzeria</h4>
                            </div>

                            <QRCode value={pizzeriaUrl} size={180} />
                            <a href={pizzeriaUrl} target="_blank" rel="noopener noreferrer" className="mt-6 bg-green-500 text-white py-3 px-6 rounded-full hover:bg-green-600 transition">Vai al menu</a>
                        </div>

                        {/* Ristorante */}
                        <div className="flex-shrink-0 w-56 flex flex-col items-center justify-center p-2 bg-white border border-gray-300 rounded-2xl  text-center">
                            <div className="flex flex-row items-center">
                                <FaUtensils size={40} className="mb-4 text-green-500 mr-4" />
                                <h4 className="text-3xl font-semibold mb-4 text-green-500">Ristorante</h4>
                            </div>
                            <QRCode value={restaurantUrl} size={180} />
                            <a href={restaurantUrl} target="_blank" rel="noopener noreferrer" className="mt-6 bg-green-500 text-white py-3 px-6 rounded-full hover:bg-green-600 transition">Vai al menu</a>
                        </div>

                        {/* Pub */}
                        <div className="flex-shrink-0 w-56 flex flex-col items-center p-2 bg-white border border-gray-300 rounded-2xl  text-center">
                            <div className="flex flex-row items-center">
                                <FaBeer size={40} className="mb-4 text-green-500 mr-4" />
                                <h4 className="text-3xl font-semibold mb-4 text-green-500">Pub</h4>
                            </div>
                            <QRCode value={pubUrl} size={180} />
                            <a href={pubUrl} target="_blank" rel="noopener noreferrer" className="mt-6 bg-green-500 text-white py-3 px-6 rounded-full hover:bg-green-600 transition">Vai al menu</a>
                        </div>

                        {/* Bar */}
                        <div className="flex-shrink-0 w-56 flex flex-col items-center p-2 bg-white border border-gray-300 rounded-2xl  text-center">
                            <div className="flex flex-row items-center">
                                <FaCoffee size={40} className="mb-4 text-green-500 mr-4" />
                                <h4 className="text-3xl font-semibold mb-4 text-green-500">Bar</h4>
                            </div>
                            <QRCode value={barUrl} size={180} />
                            <a href={barUrl} target="_blank" rel="noopener noreferrer" className="mt-6 bg-green-500 text-white py-3 px-6 rounded-full hover:bg-green-600 transition">Vai al menu</a>
                        </div>
                    </div>
                </section>

                {/* Seconda sezione */}
                <section className="bg-gray-50 p-10 rounded-2xl shadow-xl flex flex-col md:flex-row items-center gap-8 mb-16">
                    <div className="md:w-2/3">
                        <video className="w-full rounded-2xl shadow-lg transform hover:scale-105 transition duration-300"
                            controls
                            poster={nemuPosterConfVideo}
                        >
                            <source src={'https://images.nemu-app.com/nemu_demo_menu_v2.mp4'} type="video/mp4" />
                            Il tuo browser non supporta il tag video.
                        </video>
                    </div>
                    <div className="md:w-1/3">
                        <h3 className="text-3xl font-extrabold mb-6">Il menu interattivo di nemù trasforma l'esperienza del cliente</h3>
                        <ul className="list-disc pl-5 text-lg text-gray-600">
                            <li className="mb-3">Filtri personalizzati per ingredienti e allergeni</li>
                            <li className="mb-3">Interfaccia utente coinvolgente e facile da navigare</li>
                            <li>Accesso immediato a informazioni dettagliate sui piatti</li>
                        </ul>
                    </div>

                </section>

                {/* Terza sezione (senza video) */}
                <section className="bg-white p-10 rounded-2xl shadow-xl mb-16">
                    <h3 className="text-3xl font-extrabold mb-6">Unisciti alla nostra piattaforma e ottieni un menu digitale con QR Code</h3>
                    <p className="text-lg  mb-4">Come funziona?</p>
                    <ul className="list-disc pl-5 text-lg  mb-8">
                        <li className="mb-3">Compila il modulo di registrazione e crea il profilo del tuo ristorante</li>
                        <li className="mb-3">Inserisci i dettagli del tuo menu, inclusi ingredienti e allergeni</li>
                        <li>Genera automaticamente il menu digitale con QR Code da utilizzare nel tuo ristorante</li>
                    </ul>
                    <p className="text-lg  font-semibold mb-6">ATTENZIONE: Potresti avere diritto di usufruire dei nostri servizi <strong>gratuitamente e per sempre</strong>.</p>
                    <div className="flex gap-4">
                        <Button type="green" onClick={handleGoToPromo}>
                            Vai alla promo
                        </Button>
                        <Button type="green" onClick={handleRegisterClick}>
                            Registrati
                        </Button>
                    </div>
                </section>

                {showRegistration && <SignUpModal
                    showSignUpModal={showRegistration}
                    setShowSignUpModal={handleChangeRegistration}
                />}
            </main>
        </div>
    );
};

export default MenuDigitalePage;
