import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateMenuThunk } from '../redux/menusSlice';
import Input from './modal/Input';
import Toggle from './modal/Toggle';
import Modal from './modal/Modal';
import ImageSection from './modal/ImageSection';
import ColorSection from './modal/ColorSection';
import Translations from './modal/Translations';
const updateMenu = require('./logic/updateMenu.js');

const EditMenuCategory = ({
  owner,
  menu,
  menuId,
  categoryId,
  showEditMenuCategory,
  setShowEditMenuCategory,
  refreshAllMenusOwner,
  refreshMenuOwner,
  menuDefaultLanguage,
  getHandleDeleteMenuCategory
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const isLoading = useSelector((state) => state.menus.menuUpdatingIsLoading);
  const errorMessage = useSelector((state) => state.menus.errorMenuUpdating);
  const successMessage = useSelector((state) => state.menus.successMenuUpdating);
  const menuCategoryTarget = menu.menu?.menuCategory?.find(cat => cat._id === categoryId) || {};
  const [outputTranslations, setOutputTranslations] = useState([]);
  const defaultLanguage = menu.defaultLanguage;

  // Added state variables
  const [nameError, setNameError] = useState(false); // Tracks validation error for "Nome"
  const [uploadMessage, setUploadMessage] = useState(false); // Handles upload messages
  const [uploadErrorState, setUploadErrorState] = useState(false); // Handles upload errors

  const [categoryDetails, setCategoryDetails] = useState({
    color: menuCategoryTarget.color || '',
    image: menuCategoryTarget.image || '',
    price: menuCategoryTarget.price || '',
    isComplete: menuCategoryTarget.isComplete || false,
    translations: menu.menu.translations.map(lang => {
      const existingTranslation = menuCategoryTarget.translations?.find(t => t.language === lang.language);
      return {
        language: lang.language,
        name: existingTranslation ? existingTranslation.name : '',
        description: existingTranslation ? existingTranslation.description : ''
      };
    })
  });

  const [inputImage, setInputImage] = useState('');
  const [uploadedFileName, setUploadedFileName] = useState(false);

  useEffect(() => {
    const category = menu.menu?.menuCategory?.find(cat => cat._id === categoryId);
    const categoryImage = category ? category.image : null;
    setInputImage(categoryImage);
  }, [menu, categoryId]);

  useEffect(() => {
    if (uploadedFileName) {
      setCategoryDetails(prevState => ({
        ...prevState,
        image: uploadedFileName
      }));
    }
  }, [uploadedFileName]);

  const handleRemoveImage = () => {
    setCategoryDetails(prevState => ({
      ...prevState,
      image: ''
    }));

    setUploadMessage(false);
    setUploadErrorState(false);
    document.getElementById('fileInput').value = '';
  };

  const [color, setColor] = useState(menuCategoryTarget.color || '');

  const handleColorChange = (color) => {
    setColor(color.hex);
    setCategoryDetails(prevState => ({
      ...prevState,
      color: color.hex 
    }));
  };

  const deleteColor = () => {
    setColor('');
    setCategoryDetails(prevState => ({
      ...prevState,
      color: ''
    }));
  };

  useEffect(() => {
    if (outputTranslations && outputTranslations.length > 0) {
      const formattedTranslations = outputTranslations.map(lang => ({
        language: lang.language,
        name: lang.name,
        description: lang.description
      }));
      setCategoryDetails(prevCategoryDetails => ({
        ...prevCategoryDetails,
        translations: formattedTranslations || []
      }));
    }
  }, [outputTranslations]);

  const closeEditMenuCategory = () => {
    setShowEditMenuCategory(false);
  };

  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === 'checkbox' ? checked : value;

    if (name === 'name' || name === 'description') {
      if (name === 'name' && value.trim() !== '') {
        setNameError(false);
        setError(false);
      }
      setCategoryDetails(prevState => ({
        ...prevState,
        translations: prevState.translations.map(translation =>
          translation.language === defaultLanguage
            ? { ...translation, [name]: inputValue }
            : translation
        )
      }));
    } else {
      setCategoryDetails(prevState => ({
        ...prevState,
        [name]: inputValue
      }));
    }
  };

  const handleToggle = () => {
    setCategoryDetails(prevState => {
      const newIsComplete = !prevState.isComplete;
      return {
        ...prevState,
        isComplete: newIsComplete,
        price: newIsComplete ? prevState.price : '' 
      };
    });
  };

  // Define handleDeleteCategory as async function
  const handleDeleteCategory = async (menuId, categoryId) => {
    await getHandleDeleteMenuCategory(menuId, categoryId); // Execute delete function
    setShowEditMenuCategory(false); // Close modal after deletion
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validation
    const name = categoryDetails.translations.find(t => t.language === defaultLanguage)?.name.trim();
    if (!name) {
      setNameError(true);
      setError(true);
      return;
    }

    setLoading(true);
    setError(false);
    setSuccess(false);

    const updatedMenu = updateMenu(menu, categoryDetails, 'categoryUpdate', categoryId);

    try {
      const response = await dispatch(updateMenuThunk({ menuId, updatedMenu }));
      if (updateMenuThunk.fulfilled.match(response)) {
        setLoading(false);
        setSuccess(true);
        if (error) {
          setError(false);
        }
        await refreshMenuOwner(menuId);
        closeEditMenuCategory(); 
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error('Error updating the menu:', error);
      setLoading(false);
      setError(true);
    }
  };

  return (
    <>
      {showEditMenuCategory && (
        <Modal
          handleSubmit={handleSubmit}
          closeFunction={closeEditMenuCategory}
          title="Modifica Categoria Menu"
          isLoading={isLoading}
          loading={loading}
          success={success}
          successMessage={successMessage}
          error={error}
          errorMessage={errorMessage}
          deleteFunction={() => handleDeleteCategory(menuId, categoryId)} // Pass a lambda function
        >
          {/* "Nome" Input with Validation */}
          <Input
            description={categoryDetails.translations.find(t => t.language === defaultLanguage)?.name || ''}
            name='name'
            onChange={handleInputChange}
            tipo="InputText"
            hasError={nameError} // Pass the error state
            ariaDescribedby="name-error" // Enhance accessibility
          >
            <div className="text-2xl">
              Nome <span className="text-red-500">*</span> {/* Indicate required */}
            </div>
          </Input>
          { nameError && <span id="name-error" className="text-red-500">Il nome della categoria è obbligatorio.</span> }

          {/* "Descrizione" Input */}
          <Input
            description={categoryDetails.translations.find(t => t.language === defaultLanguage)?.description || ''}
            name='description'
            onChange={handleInputChange}
            tipo="InputText"
          >
            <div className="text-2xl">Descrizione</div>
          </Input>

          <hr className="border-gray-400 mt-8"></hr>
          <div className="text-2xl font-bold mt-4">Traduzioni</div>

          <Translations
            defaultLanguage={defaultLanguage}
            inputTranslations={categoryDetails.translations}
            outputTranslations={outputTranslations}
            setOutputTranslations={setOutputTranslations}
          />

          <hr className="border-gray-400 mt-8"></hr>
          <div className="text-2xl font-bold mt-4">Tipo di menu</div>

          <div className='flex flex-col items-start mt-6'>
            <div className='flex flex-row items-center justify-center gap-2 my-1'>
              <span className='font-bold'>Menu Completo</span>

              <div>
                <Toggle
                  isOn={categoryDetails.isComplete}
                  handleToggle={handleToggle}
                />
              </div>
            </div>
            {categoryDetails.isComplete && (
              <div className='flex flex-row justify-between gap-2 items-center my-1'>
                <h3 className='font-bold'>Prezzo</h3>
                <div className='p-2 w-40 flex flex-row justify-between items-center'>
                  <input
                    className='border rounded-lg border-gray-400 p-2 bg-transparent'
                    value={categoryDetails.price}
                    name='price'
                    onChange={handleInputChange}
                    type='number'
                    min="0"
                  />
                </div>
              </div>
            )}
          </div>
          <hr className="border-gray-400 mt-8"></hr>
          <div className="text-2xl font-bold mt-4">Layout</div>

          <ColorSection
            handleColorChange={handleColorChange}
            deleteColor={deleteColor}
            color={color}
          />

          {/* ImageSection with Upload States */}
          <ImageSection
            inputImage={inputImage}
            handleRemoveImage={handleRemoveImage}
            setUploadedFileName={setUploadedFileName}
            aspect={3 / 1}
            maxSizeMB={0.3}
            maxWidthOrHeight={1920}
            uploadMessage={uploadMessage}
            setUploadMessage={setUploadMessage}
            uploadError={uploadErrorState}
            setUploadError={setUploadErrorState}
          />

          <hr className="border-gray-400 mt-4"></hr>
        </Modal>
      )}
    </>
  );
};

export default EditMenuCategory;
